body {
  margin: 0;
}
.pointer:hover {
  cursor: pointer;
}

.MuiToolbar-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-height: 48px !important;
}
.MuiToolbar-root .MuiTypography-root {
  padding: 0px !important;
}

body.dragging {
  cursor: grabbing !important;
}
.MuiListItemIcon-root {
  min-width: 35px !important;
}

.MuiDataGrid-root {
  border-radius: 0px !important;
}
.MuiDataGrid-cell fieldset.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnHeaderTitleContainerContent {
  width: 100%;
}
.blink {
  animation: blink-animation 0.8s steps(5, start) infinite;
  -webkit-animation: blink-animation 0.8s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.MuiDataGrid-footerContainer .MuiFormLabel-root  {
  left: 5px;
}
.MuiPopover-root.MuiMenu-root {
  z-index: 25000;
}
.highlight {
  background-color: #b39ddb;
}